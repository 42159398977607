import {React, useState} from "react";
import classes from './Layout.module.css';
import Toolbar from "../../UI/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../UI/Navigation/SideDrawer/SideDrawer";


export default function Layout(props) {

  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  }

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  }

  return (
    <div className={classes.Layout}>
      <Toolbar 
        role={props.role}
        email={props.email}
        drawerToggleClicked={sideDrawerToggleHandler}
        login={props.login}

      />
      <SideDrawer 
        closed={sideDrawerClosedHandler} 
        open={showSideDrawer}        
        role={props.role}
        email={props.email}
        login={props.login}
        />
      <main className={classes.Body}>
        {props.children}
      </main>

      <footer className={classes.Footer}>
        <hr/>
        © 2022 by OSHC+. Proudly designed and built by OSHC+
      </footer>
    </div>
  )
}