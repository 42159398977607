import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const navigationItem = (props) => {

    let navItem;

    if (props.infoOnly) {
        navItem = (
            <span className={props.clicked ? classes.Clickable : classes.Info} onClick={props.clicked}>
                {props.children}
            </span>
        );

    } else {
        navItem = (
            <NavLink activeClassName={classes.active} to={props.link} exact>
                {props.children}
            </NavLink>
        );
    }

    return (

        <li className={classes.NavigationItem}>
            {navItem}
        </li>

    );


};


  
export default (navigationItem);