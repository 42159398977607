import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const navigationItems = (props) => {

    let roleSpecificLinks = null;
    let infoItems = null;
    let login = <NavigationItem infoOnly  clicked={props.login}>Login</NavigationItem>;

    roleSpecificLinks = (
      <Aux>
        <NavigationItem link="/home">Home</NavigationItem>
        <NavigationItem link="/contact">Contact</NavigationItem>
        <NavigationItem link="/privacy">Privacy</NavigationItem>
        {/* <NavigationItem infoOnly link="www.oshcplus.com">Login</NavigationItem> */}
      </Aux>
      );
  
  
    return (
    <ul className={classes.NavigationItems}>
        {infoItems}
        {roleSpecificLinks}
        {login}

    </ul>

    );

}

export default navigationItems;