import classes from './Contact.module.css'


const Contact = () => {
    return (
        <div className={classes.Contact}>
            <div className={classes.Title}>Contact OSHC<span className={[classes.PlusColor, classes.Superscript].join(' ')}>+</span></div>
            <br/>
            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '25px'}}>
                <button className={["btn", "btn-large", "badge-pill", classes.Button, classes.PlusColorBackground].join(" ")}>Request a Demo</button>
                <button className={["btn", "btn-large", "badge-pill", classes.Button, classes.PlusColorBackground].join(" ")} >Call our Team</button>
                <button className={["btn", "btn-large", "badge-pill", classes.Button, classes.PlusColorBackground].join(" ")} >Support Form</button>
                <button className={["btn", "btn-large", "badge-pill", classes.Button, classes.PlusColorBackground].join(" ")} >Email Us</button>
            </div> */}
            <div style={{textAlign: 'left', paddingTop: '50px'}}>
                {/* <p className={classes.Subtitle}>Office Hours: 8:30am to 4:30pm AEST</p> */}
                {/* <p className={classes.Subtitle}>Phone: (02) 6554 8905</p> */}
                <p className={classes.Subtitle}>To express your interest, please email info@oshcplus.com.au</p>
                {/* <p className={classes.Subtitle}>Sales Enquiries: sales@oshcplus.com.au</p> */}
                {/* <p className={classes.Subtitle}><b>Support Enquiries: support@oshcplus.com.au</b></p> */}

            </div>
        </div>

    );
}

export default Contact;