// import { Fragment } from "react";
import classes from './Home.module.css'
import discoverImg from '../../assets/images/discover.png';
import feature1 from '../../assets/images/feature1.webp';
import feature2 from '../../assets/images/feature2.webp';
import feature3 from '../../assets/images/feature3.png';
import feature4 from '../../assets/images/feature4.webp';
import feature5 from '../../assets/images/feature5.webp';
import feature6 from '../../assets/images/feature6.webp';
import feature7 from '../../assets/images/feature7.png';
import feature8 from '../../assets/images/feature8.webp';
import { Fragment } from 'react';
import Contact from './Contact';



const Home = (props) => {

    const Discover = () => {
        return (
            <div className={classes.Discover}>
                <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className={classes.DiscoverImg} style={{margin: 'auto'}}>
                        <img src={discoverImg} alt="Discover OSHC+" width="100%"/>
                    </div>
                    <div style={{display: 'flex', maxWidth: '425px', flexDirection: 'column', justifyContent: 'space-between', margin: 'auto'}}>
                        <div className={classes.Title}>Discover OSHC<span className={[classes.PlusColor, classes.Superscript].join(' ')}><b>+</b></span></div>
                        <p className={classes.Subtitle}>The Dedicated Outside of School Hours Platform built by OSHC for OSHC</p>
                    </div>
                </div>
                <br />
                <p className={classes.Paragraph}>
                    OSHC<span className={classes.Superscript}>+</span> is your dedicated OSHC platform. Developed by OSHC providers for OSHC providers. The platform covers end to end Provider requirements. With Centre Management and Operation, Centre Kiosks, Full Compliance, Check lists, Financial management and reporting. All in a modern intuitive web application and IOS and Android mobile application for Parents and Guardians.
                </p>
            </div>

        )
    }

    const Feature = (props) => {
        return (
            <div className={classes.Feature}>
                <img src={props.imgSrc} width='100px' alt="feature"/>
                <p style={{fontSize: '1.1rem', padding: '20px 0', fontWeight: '500'}}>{props.text}</p>
            </div>
        )
    }

    const Features = () => {
        return (
            <Fragment >
                 <div className={classes.Title}>OSHC<span className={[classes.PlusColor, classes.Superscript].join(' ')}>+</span> Features</div>
                 <div style={{display: 'flex', flexFlow: 'row wrap', padding: '25px 0'}}>
                    <Feature imgSrc={feature1} text="Only the features you need. Nothing you don't." />
                    <Feature imgSrc={feature2} text="Complete CCSS &#38; ISMS integration for Centrelink payments" />
                    <Feature imgSrc={feature3} text="All session rolls include an optional bus list function" />
                    <Feature imgSrc={feature4} text="Xero integration for parent statements &#38; CCS income tracking" />
                    <Feature imgSrc={feature5} text="Parent accounts with session bookings &#38; enrolments" />
                    <Feature imgSrc={feature6} text="Multi-centre management options" />
                    <Feature imgSrc={feature7} text="At a glance medical &#38; dietary information in roll view" />
                    <Feature imgSrc={feature8} text="Parent notifications &#38; permissions directly in app" />
                 </div>
            </Fragment>

        )
    }





    return(
        <div className={classes.Home}>
            <Discover/>
            <Features/>
            <Contact />
        </div>
    )



}


export default Home;







































        // ORIGINAL DESIGN



        // <Fragment>
        //     <div className={classes.OutterBox}>
        //         <div className={classes.InnerBox}>
        //             <div className={classes.ImageContainer}>
        //                 <img alt="logo" src={logo} className={classes.ImageLogo}></img>
        //             </div>
        //             <p className={classes.Heading}> The Dedicated Outisde of School Hours Platform</p>
        //             <p className={classes.Text}>
        //                 OSHC + is your dedicated OSHC platform. Developed by OSHC providers for OSHC providers. The platform covers end to end Provider requirements. With Centre Management and Operation, Centre Kiosks, Full Compliance, Check lists, Financial management and reporting. All in a modern intuitive web application and IOS and Android mobile application for Parents and Guardians.
        //             </p>
        //             <a className={classes.Link} href="/about">More Information</a>
        //             <a className={classes.Link} href="/contact">Contact us</a>
        //             {/* <footer className={classes.LinkSmallDiv}>
        //             <a className={classes.SmallLink} href="">About</a>
        //             <span className={classes.SpaceBetweenLink}>|</span>
        //             <a className={classes.SmallLink} href="">Privacy</a>
        //             </footer> */}
        //         </div>
        //         <div className={classes.InnerBox}>
        //             <img src={kid} alt='Kid with soccer Ball'></img>
        //         </div>
        //     </div>

        // </Fragment>