

// import { Fragment } from 'react'
import classes from './Privacy.module.css'

const Privacy = (props) => {


  return(
      <div className={classes.Privacy}>
        <div className={classes.Title}>OSHC<span className={[classes.PlusColor, classes.Superscript].join(' ')}>+</span> Privacy Notice</div>
        <p className={classes.Paragraph}>Privacy policy currently being updated, please check back soon.</p>
      </div>
  )


}

export default Privacy