import './App.css';
import { React} from 'react';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import { useHistory } from "react-router-dom";
import 'url-search-params-polyfill';
import Layout from './hoc/Layout/Layout';
import Home from './Components/LandingPage/Home';
import Contact from './Components/LandingPage/Contact';
import Privacy from './Components/LandingPage/Privacy';
import { appURL } from './utilities/globalVariables';

function App() {

  // react router history instance to navigate user to other pages
//   let history = useHistory();    

    const login = () => {
        window.location.replace(appURL);
        console.log('redirect to login for app')
    }

    // let routes = 'Guest';
    let routes = null;
    routes = (
    <Switch>
        <Route path="/home" render={(props) => <Home {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route path="/privacy" render={(props) => <Privacy {...props} />} />
        <Route path='/login' component={() => { 
            window.location.href = appURL; 
            return null;
        }}/>
        <Redirect to="/home" />
    </Switch>
    );

  return (
      <div className="App">
          <Layout login={login}>            
            {routes}
          </Layout>
      </div>
  );
}

export default App;
